@import-normalize; 

:root {
  /* Fonts */
  --font-halant: "Halant", serif;
  --font-jost: "Jost", serif;
  --font-roboto: "Roboto", serif;
  /* Colors */
  --color-white: #ffffff;
  --color-gold: #e2bb53;
  --color-grey: #6b6b6b;
  --color-green: #0b4d25;
  --color-darkgreen: #233539;
  --color-darkergreen: #203033;
  --color-black: #000000;
  --color-vanilla: #F4F0EA;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}


